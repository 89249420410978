@use "../vars";
@use "../fonts";

header {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100vw;
}

/* Logged-in Header */

.header--blur {
  background: rgba(255, 255, 255, 0);
  box-shadow: 1px 1px 5px vars.$header--shadow;
  backdrop-filter: blur(4px);
}

header > .container {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: fit-content;
  padding: 0px 20px;
  padding-right: 10px;
}

header > .container > div {
  padding-right: 20px;
}

header a {
  font-size: 0.6em;
  color: vars.$primary;
  text-decoration: none;
}

.logo > a {
  font-size: 0.8em;
  font-family: "Yellowtail", cursive;
  color: vars.$logo--link;
}

.logo {
  margin: 15px 0;
}

.logged--in--logo {
  display: inline-block;
  margin: 0;
}

.logged--in--logo > a {
  font-size: 20px;
  font-family: "Yellowtail", cursive;
  color: vars.$logo--link--logged--in;
}

.hello--user {
  z-index: 1;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  top: 10px;
  background: unset;
  border: unset;
  border-radius: unset;
  font-size: 0.9em;
  color: vars.$primary;
  cursor: pointer;
  transition: unset;
}

.hello--user > span:nth-child(1) {
  display: inline-block;
  font-weight: 400;
}
.hello--user > span:nth-child(1) > strong {
  display: inline-block;
  font-weight: 600;
}

.avatar-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  font-size: 10px;
}

.avatar {
  width: 40px;
}

/* Logged-out Header */

.login--btn,
.signup--btn,
.about--btn {
  text-align: center;
  position: relative;
  top: 30px;
  width: fit-content;
  margin-left: 20px;
  padding: 6px;
  background: transparent;
  border: solid 1px vars.$primary;
  border-radius: 20px;
  font-size: 1.3em;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}

.login--btn,
.about--btn {
  border: solid 1px transparent;
}

.signup--btn:hover {
  background: vars.$signup--form--bkg;
}

.login--btn:hover {
  background: vars.$login--form--bkg;
}

.about--btn:hover {
  background: vars.$about--link--bkg--hover;
}

@media screen and (max-width: 450px) {

  .login--btn,
  .signup--btn,
  .about--btn {
    margin-left: 5px;
    font-size: 1.2em;
  }

  header {
    height: 50px;
  }

  header > .container {
    padding: 0 10px;
  }

  .about--login--signup--nav {
    position: relative;
    top: -15px;
    right: -12px;
    padding-right: 0px;
  }

  .logo,
  .logged--in--logo {
    margin: 0;
  }

  .logo > a,
  .logged--in--logo > a {
    font-size: 0.6em;
  }

  .hello--user {
    right: -15px;
    font-size: 0.8em;
  }

  .avatar-wrapper {
    height: 30px;
    width: 30px;
  }

  .avatar {
    width: 30px;
  }
  
}
