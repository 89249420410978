@use "../fonts";
@use "../vars";

label,
input {
  display: block;
  color: vars.$primary;
}

label {
  font-size: 14px;
}

.disabled--btn {
  text-decoration: line-through;
}

.no--button {
  display: none;
}

input {
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  max-width: 274px;
  margin: 0 auto;
  margin-top: 6px;
  margin-bottom: 20px;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

::placeholder {
  color: vars.$light-text;
}

.form--container--login,
.form--container--signup,
.form--container--reset--password,
.form--container--forgot--password--form,
.form--container--workout--form,
.form--container--edit--workout--form,
.form--container--user--settings {
  position: absolute;
}

/* Login & Signup form */

.form--container--login,
.form--container--signup {
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.form--container--login {
  background-image: url("../../assets/pastel-gym-1.jpeg");
}

.form--container--signup {
  background-image: url("../../assets/pastel-gym-2.jpeg");
  
  .temp {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    width: 250px;
    padding: 5px 10px 5px 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(241, 228, 43);
    border: 2px solid rgb(255, 0, 0);
    border-radius: 10px;
    font-size: 16px; 
    color: vars.$primary;  
    p {
      margin: 3px;
      &:nth-child(n + 2) {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.form--container--reset--password {
  z-index: 5;
  display: flex;
  width: 100vw;
  background: #c3f1fc;
}

.login,
.signup {
  z-index: 0;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 0 50px;
  background: vars.$signup--form--bkg;
  box-shadow: none;
}

.login {
  background: vars.$login--form--bkg;
}

.login > label,
.signup > label {
  color: vars.$login--signup--form--text;
  font-size: 0.9em;
}

form.login h4,
form.signup h4 {
  margin-top: 200px;
  border-bottom: solid 1px vars.$login--signup--form--text;
  color: vars.$login--signup--form--text;
  font-size: 20px;
  font-weight: 500;
}

.log-in--form--btn,
.sign-up--form--btn {
  padding: 10px 30px;
  background: vars.$primary;
  border: solid 1px vars.$primary;
  border-radius: 50px;
  font-family: "Poppins";
  color: vars.$login--signup--form--text;
  cursor: pointer;
  transition: 0.2s;
}

.log-in--form--btn:hover,
.sign-up--form--btn:hover {
  background: vars.$primary--inverse;
  color: vars.$primary;
}

/* Forgot password button */

.forgot--password {
  position: relative;
  top: -15px;
  padding: 10px 30px;
  background: unset;
  border: unset;
  font-size: 0.8em;
  font-family: "Poppins";
  color: vars.$forgot-password--btn--text;
  text-decoration: underline;
  cursor: pointer;
}

.forgot--password:hover {
  color: vars.$forgot-password--btn--text--hover;
}

/* Forgot password form */

.form--container--forgot--password--form {
  z-index: 5;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: vars.$form--container--bkg;
}

.forgot--password--form {
  text-align: center;
  position: relative;
  top: 180px;
  height: fit-content;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background: vars.$forgot-password--form--bkg;
  border-radius: 20px;
  box-shadow: vars.$shadow--2;
}

.forgot--password--form > h4 {
  border-bottom: vars.$forgot-password--form--title--underline;
}

.forgot--password--form > .proceed {
  padding: 8px 28px;
  background: vars.$primary;
  border: solid 1px vars.$primary;
  border-radius: 50px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.2s;
}

.forgot--password--form > .proceed:hover {
  background: vars.$primary--inverse;
  color: vars.$primary;
}

/* Reset password form (accessed from the reset link) */

.form--container--reset--password {
  z-index: 5;
  display: flex;
  text-align: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.reset--password {
  position: relative;
  top: 180px;
  height: fit-content;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background: vars.$reset-password--form--bkg;
  border-radius: 20px;
  box-shadow: vars.$shadow--2;
}

.reset--password > button {
  padding: 8px 28px;
  background: vars.$primary;
  border: solid 1px vars.$primary;
  border-radius: 50px;
  font-family: "Poppins";
  color: vars.$all--form--btn--text;
  cursor: pointer;
  transition: 0.2s;
}

.reset--password > button:hover {
  background: vars.$primary--inverse;
  color: vars.$primary;
}

/* Add & Edit workout forms */

.form--container--workout--form,
.form--container--edit--workout--form {
  z-index: 5;
  display: flex;
  top: -70px;
  left: -22%;
  height: 100vh;
  width: 150%;
  background: vars.$form--container--bkg;
}

.workout--form > h4,
.edit--form > h4 {
  margin: 20px 0;
  padding: 0;
  border-bottom: solid 1px rgb(188, 188, 188);
  font-size: 1.2em;
  font-weight: 600;
}

.edit--form,
.workout--form {
  text-align: center;
  position: relative;
  top: 100px;
  height: fit-content;
  max-width: 400px;
  min-width: 300px;
  margin: 0 32%;
  padding: 20px;
  background: vars.$workout--form--bkg;
  border-radius: 20px;
  box-shadow: 5px 5px 25px 5px rgba(22, 22, 22, 0.307);
}
.edit--form > select,
.workout--form > select {
  margin-bottom: 10px;
  padding: 2px;
  border: unset;
  border-radius: 2px;
}

.edit--form > label,
.workout--form > label {
  font-weight: 500;
}

.edit--form > input,
.workout--form > input {
  width: 70%;
}

.edit--form {
  background: vars.$edit--form--bkg;
}

.workout--form--btn,
.edit--form--btn {
  margin: 10px;
  padding: 10px 30px;
  background: vars.$primary;
  border: solid 1px vars.$primary;
  border-radius: 50px;
  font-family: "Poppins";
  color: vars.$all--form--btn--text;
  cursor: pointer;
  transition: 0.2s;
}

.workout--form--btn:hover,
.edit--form--btn:hover {
  background: vars.$primary--inverse;
  color: vars.$primary;
}

.add--workout {
  grid-area: add-btn;
  height: 40px;
  max-width: 140px;
  margin-top: 10px;
  background: vars.$primary;
  border: solid 1px vars.$primary;
  border-radius: 50px;
  font-size: 0.9em;
  font-weight: 500;
  font-family: "Poppins";
  color: vars.$all--form--btn--text;
  cursor: pointer;
  transition: 0.2s;
}

.is--loading {
  cursor: default;
  filter: opacity(0.7);
}

.add--workout:hover {
  background: vars.$primary--inverse;
  color: vars.$primary;
}

/* User/Profile settings form */

.form--container--user--settings {
  z-index: 3;
  display: flex;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: vars.$form--container--bkg;
}

.user--settings {
  text-align: left;
  position: absolute;
  top: 70px;
  right: 18%;
  height: fit-content;
  max-width: 400px;
  min-width: 300px;
  padding: 20px;
  padding-left: 50px;
  background: vars.$user-settings--form--bkg;
  border-radius: 20px;
  box-shadow: vars.$shadow--2;
}

.user--settings > input {
  width: 200px;
  margin-left: 0;
}

.max-chars-error {
  position: relative;
  top: -18px;
  margin: 0;
  padding-left: 2px;
  font-size: 12px;
  color: vars.$error;
}

.user--settings > h4 {
  border-bottom: vars.$user-settings--form--title--underline;
  color: vars.$primary;
}

.user--settings > label {
  font-size: 0.8em;
  font-weight: 500;
}

.upload--image {
  padding: 30px 0;
}

.upload--btn {
  height: 30px;
  padding: 5px 20px;
  background: vars.$primary;
  border: solid 1px vars.$primary;
  border-radius: 50px;
  font-family: "Poppins";
  color: vars.$all--form--btn--text;
  cursor: pointer;
  transition: 0.2s;
}

.upload--btn:hover {
  background: vars.$primary--inverse;
  color: vars.$primary;
}

.remove--profile--img {
  font-size: small;
}

.close {
  position: absolute;
  right: 20px;
  height: 1em;
  width: 1em;
  padding: 0;
  background: unset;
  border: unset;
  color: vars.$primary;
  cursor: pointer;
  transition: unset;
}

.close:hover {
  background: unset;
}

.user--settings > img {
  display: block;
  padding-bottom: 20px;
}

.cropper--wrapper {
  display: block;
  position: relative;
  height: 250px;
  margin-bottom: 20px;
}

.delete--account--btn,
.download--data--btn {
  display: block;
  position: relative;
  left: 200px;
  margin-top: 10px;
  padding: 0;
  background: unset;
  border: unset;
  border-bottom: 1px solid vars.$primary;
  color: vars.$primary;
  line-height: 90%;
  cursor: pointer;
}

.delete--account--btn:hover,
.download--data--btn:hover {
  border-bottom: 1px solid orangered;
  color: orangered;
}

@media screen and (max-width: 600px) {
  .form--container--login,
  .form--container--signup {
    display: flex;
    justify-content: center;
    background-image: none;
  }

  .form--container--login {
    background: vars.$login--form--bkg;
  }

  .form--container--signup {
    background: vars.$signup--form--bkg;
  }

  .signup,
  .login {
    position: relative;
    left: unset;
    padding: 0;
    background: none;
  }

  .log-in--form--btn,
  .sign-up--form--btn {
    margin: 0 auto;
  }
}

@media screen and (max-width: 450px) {
  .add--workout {
    z-index: 3;
    width: 100%;
    max-width: 200px;
    min-width: 100px;
    margin: 20px auto 10px auto;
    background: #373737ed;
  }

  .form--container--workout--form,
  .form--container--edit--workout--form {
    z-index: 5;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: vars.$form--container--bkg;
  }

  .edit--form,
  .workout--form {
    width: 250px;
    min-width: unset;
    margin: 0 16%;
    padding: 10px;
  }

  .user--settings {
    right: 7%;
  }
}
