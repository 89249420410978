@use "components/chart";
@use "components/forms";
@use "components/header";
@use "components/pagination";
@use "components/searchBar";
@use "components/userMenu";
@use "components/workouts";
@use "components/deleteAccount";
@use "pages/about";
@use "pages/home";
@use "pages/confirmedAccount";
@use "fonts";
@use "successAndErrorMessages";
@use "vars";
@use "loader";

body {
  overflow-x: hidden;
  background: vars.$home--bkg;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.fallback--animation--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.fallback--animation--container h1 {
  color: vars.$primary;
}

.fallback--animation--container .lds-ellipsis {
  top: 5px;
}

.fallback--animation--container .lds-ellipsis div {
  background: vars.$primary;
}

.spun--down--server--alert,
.cookie--alert {
  z-index: 5;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 25px;
  max-width: 320px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.624);
  border: solid 1px rgb(13, 56, 91);
  border-left: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
  color: rgb(13, 56, 91);

  p {
    margin: 5px 3px;
  }

  button {
    padding: 3px 10px;
    background: rgb(13, 56, 91);
    border: solid 1px vars.$primary;
    border-radius: 50px;
    font-size: 12px;
    font-family: "Poppins";
    color: vars.$login--signup--form--text;
    cursor: pointer;
    transition: 0.2s;
  }
}

.spun--down--server--alert {
  z-index: 6;
}

.hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

@media screen and (max-width: 450px) {

  .spun--down--server--alert {
    width: 95%;
    max-width: none;
  }
  
}
