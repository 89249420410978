@use "../vars";

.confirmed--container {
  margin-top: 80px;
  padding: 20px;
}

.confirmed--account--success {
  padding: 20px 40px;
  background: vars.$success--bkg;
}

.already--confirmed {
  max-width: 480px;
  margin-top: 20px;
  padding: 20px;
  border: solid 5px vars.$success--bkg;
  font-size: 1em;
  color: vars.$primary;
}
