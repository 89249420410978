@use "../fonts";
@use "../vars";

.get--started {
  text-align: center;
  position: absolute;
  top: 250px;
  left: 41%;
  width: 200px;
  font-weight: 600;
  color: vars.$chart-title--color;
}

.no--workouts--found {
  text-align: center;
  width: 170px;
  font-weight: 600;
  color: vars.$chart-title--color;
}

.no--workouts--yet {
  position: absolute;
  top: 27vh;
  width: 150px;
  left: -30px;
  //right: 0;
  background-image: linear-gradient(
    0deg,
    rgb(219, 162, 215),
    rgb(212, 122, 147),
    rgb(162, 122, 212),
    rgb(122, 131, 212),
    rgb(99, 148, 255),
    rgb(99, 224, 255),
    rgb(99, 255, 239),
    rgb(99, 255, 140),
    rgb(255, 206, 99),
    rgb(255, 127, 99),
    rgb(219, 162, 215),
    rgb(212, 122, 147)
  );
  background-size: 100% 1100%;
  animation: no--workouts--yet 20s linear infinite;
}

.no--workouts--yet::after {
  position: absolute;
  content: "Get started. No pressure. 🥤";
  top: 50px;
  left: 22px;
  width: 100px;
  color: vars.$chart-title--color;
}

@keyframes no--workouts--yet {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.loading--workouts {
  text-align: center;
  font-weight: 600;
  color: vars.$chart-title--color;
}

.loading--workouts {
  z-index: 5;
  position: absolute;
  top: 50px;
  left: 180px;
  animation: loading--workouts 1s ease-in-out infinite;
}

@keyframes loading--workouts {
  0% {
    color: vars.$placeholder--color-1;
  }
  50% {
    color: vars.$placeholder--color-2;
  }
  100% {
    color: vars.$placeholder--color-1;
  }
}

/* Workouts container */

.workouts--container {
  grid-area: workouts;
  height: fit-content;
  overflow-y: hidden;
  margin-left: 40px;
  padding-bottom: 0;
}

.workouts--placeholder {
  grid-area: workouts;
  position: relative;
  filter: opacity(0.7);
}

.workouts--placeholder > div {
  height: 120px;
  max-width: 250px;
  margin: 0 0 15px 0;
  padding: 15px;
  border-radius: 20px;
  font-size: 1em;
  color: white;
  animation: placeholder--animation 1s ease-in-out infinite;
}

/* Workout details component */

.workout-details {
  position: relative;
  height: 120px;
  max-width: 250px;
  margin: 0 0 15px 0;
  padding: 15px;
  background: vars.$workout-details--bkg;
  border-radius: 20px;
  box-shadow: vars.$shadow--1;
  &:nth-child(n + 4) {
    display: none;
  }
}

.workout-details h4 {
  margin: 0 0 8px 0;
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: vars.$workout-details--title--underline;
  font-size: 1em;
  font-weight: 700;
  color: vars.$primary;
  text-transform: capitalize;
}

.workout-details p {
  margin: 0;
  padding: 3px 20px;
  font-size: 0.8em;
}

.workout-details > button {
  position: absolute;
  top: 110px;
  right: 15px;
  background: unset;
  border: unset;
  border-radius: unset;
  font-size: 1.3em;
  color: vars.$light-text;
  cursor: pointer;
  transition: unset;
}

.workout-details > button:hover {
  color: vars.$light-text--hover;
}

.workout-details p > strong {
  font-weight: 600;
}

.workout-details .edit {
  right: 45px;
}

.date {
  color: vars.$light-text;
}

@media screen and (max-width: 450px) {
  .get--started {
    top: 260px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .no--workouts--found {
    text-align: center;
    margin: 0 auto;
  }

  .no--workouts--yet {
    top: 23vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .workouts--container {
    display: inline-block;
    margin-left: 0;
  }

  .workout-details,
  .workouts--placeholder {
    margin-left: auto;
    margin-right: auto;
  }

  .workouts--placeholder {
    top: 50px;
    width: 280px;
  }
}
