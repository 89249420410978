@use "../vars";
@use "../fonts";

.delete--account--dialogue {
  z-index: 15;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  top: 300px;
  right: 50px;
  width: fit-content;
  padding: 20px;
  background: pink;
  border: solid 1px orangered;
  border-radius: 10px;
  box-shadow: vars.$shadow--2;
}

.delete--account--dialogue > p {
  margin: 0;
  padding: 5px;
  font-size: 0.8em;
  font-weight: 500;
}

.delete--account--dialogue > h4 {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.delete--account--dialogue--btns {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.delete--account--dialogue--btns > button {
  padding: 3px 8px;
  background: vars.$primary;
  border-radius: 5px;
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  color: vars.$all--form--btn--text;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .delete--account--dialogue {
    width: 280px;
  }

  .delete--account--dialogue--btns {
    gap: 10px;
    margin-top: 5px;
    font-size: 0.8em;
  }
}
