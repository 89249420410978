@use "../vars";
@use "../fonts";

.chart--placeholder {
  grid-area: chart;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: 100%;
  filter: opacity(0.7);
}

@keyframes placeholder--animation {
  0% {
    background: vars.$placeholder--color-1;
  }
  50% {
    background: vars.$placeholder--color-2;
  }
  100% {
    background: vars.$placeholder--color-1;
  }
}

.chart--placeholder--title {
  height: 20px;
  width: 200px;
  border-radius: 2px;
  animation: placeholder--animation 1s ease-in-out infinite;
}

.chart--placeholder--circle {
  height: 300px;
  width: 300px;
  border-radius: 100%;
  animation: placeholder--animation 1s ease-in-out infinite;
}

.chart--placeholder--legend {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  width: 200px;
  margin-top: 10px;
}

.chart--placeholder--legend > span {
  height: 10px;
  width: 60px;
  border-radius: 2px;
  animation: placeholder--animation 1s ease-in-out infinite;
}

/* Chart */

.chart--container {
  grid-area: chart;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chart {
  position: relative;
  width: 300px;
  margin: 25px auto 0 auto;
}

.chart--container > h3 {
  position: relative;
  width: fit-content;
  margin: 10px auto 0 auto;
  color: vars.$chart-title--color;
}

.custom--legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
  padding: 0;
}

.muscle--group {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 500;
  color: vars.$primary;
  list-style: none;
}

.muscle--group:hover {
  filter: saturate(4);
}

.zero--percent {
  color: vars.$light-text;
}

.muscle--group > span {
  position: relative;
  top: -2px;
  margin: 0 2px;
  padding: 0px 5px;
  border-radius: 20px;
  font-size: 8px;
}

@media screen and (max-width: 450px) {

  .chart--placeholder--circle {
    height: 200px;
    width: 200px;
  }

  .chart {
    height: 280px;
    width: 200px;
    margin-bottom: 15px;
  }

  .chart--container > h3 {
    font-size: 1em;
  }

  .custom--legend {
    width: 100%;
  }
  
}
